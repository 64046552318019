<template>
  <div class="viewport" ref="viewport">
    <modal ref="modal" @reconnect="connect()"/>
    <!-- tabindex allows for div to be focused -->

    <div id="display" ref="display" tabindex="0"/>

  </div>
</template>

<script>
import Guacamole from 'guacamole-common-js';
import GuacMouse from '@/components/remote-connection/lib/GuacMouse';
import GuacStates from '@/components/remote-connection/lib/GuacStates';
import GuacClipboard from '@/components/remote-connection/lib/GuacClipboard';
import Modal from '@/components/remote-connection/GuacamoleDialogs.vue';

Guacamole.Mouse = GuacMouse.mouse;

export default {
  components: {
    Modal,
  },
  data() {
    return {
      connected: false,
      display: null,
      currentAdjustedHeight: null,
      client: null,
      keyboard: null,
      mouse: null,
      lastEvent: null,
      connectionState: GuacStates.IDLE,
      code: '',
      arguments: {},
      authToken: '',
      identifier: '',
    };
  },
  watch: {
    connectionState(state) {
      this.$refs.modal.show(state, this.code);
      this.code = '';
    },
  },
  methods: {
    send(cmd) {
      if (!this.client) {
        return;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const c of cmd.data) {
        this.client.sendKeyEvent(1, c.charCodeAt(0));
      }
    },
    copy(cmd) {
      if (!this.client) {
        return;
      }
      GuacClipboard.cache = {
        type: 'text/plain',
        data: cmd.data,
      };
      GuacClipboard.setRemoteClipboard(this.client);
    },
    handleMouseState(mouseState) {
      const scaledMouseState = {
        ...mouseState,
        x: mouseState.x / this.display.getScale(),
        y: mouseState.y / this.display.getScale(),
      };
      this.client.sendMouseState(scaledMouseState);
    },
    resize() {
      const elm = this.$refs.viewport;
      if (!elm || !elm.offsetWidth) {
        // resize is being called on the hidden window
        return;
      }

      const pixelDensity = window.devicePixelRatio || 1;
      const width = elm.clientWidth * pixelDensity;
      const height = elm.clientHeight * pixelDensity;
      if (this.display.getWidth() !== width || this.display.getHeight() !== height) {
        if (!height || !width) {
          this.client.sendSize(1, 1);
          return;
        }

        this.client.sendSize(width, height);
      }
      // setting timeout so display has time to get the correct size
      setTimeout(() => {
        const scale = Math.min(
          elm.clientWidth / Math.max(this.display.getWidth(), 1),
          elm.clientHeight / Math.max(this.display.getHeight(), 1),
        );
        this.display.scale(scale);
      }, 100);
    },
    connect() {
      let tunnel;
      if (window.WebSocket) {
        tunnel = new Guacamole.ChainedTunnel(
          new Guacamole.WebSocketTunnel('/guacamole/websocket-tunnel'),
          new Guacamole.HTTPTunnel('tunnel'),
        );
      } else {
        tunnel = new Guacamole.HTTPTunnel('tunnel');
      }

      if (this.client) {
        this.display.scale(0);
        this.uninstallKeyboard();
      }

      this.client = new Guacamole.Client(tunnel);
      GuacClipboard.install(this.client);

      tunnel.onerror = (error) => {
        this.code = error.code;
        // eslint-disable-next-line no-console
        this.connectionState = GuacStates.TUNNEL_ERROR;
      };

      tunnel.onstatechange = (state) => {
        // eslint-disable-next-line default-case
        switch (state) {
        // Connection is being established
        case Guacamole.Tunnel.State.CONNECTING:
          this.connectionState = GuacStates.CONNECTING;
          break;

          // Connection is established / no longer unstable
        case Guacamole.Tunnel.State.OPEN:
          this.connectionState = GuacStates.CONNECTED;
          break;

          // Connection is established but misbehaving
        case Guacamole.Tunnel.State.UNSTABLE:
          // TODO
          break;

          // Connection has closed
        case Guacamole.Tunnel.State.CLOSED:
          this.connectionState = GuacStates.DISCONNECTED;
          break;
        }
      };

      this.client.onstatechange = (clientState) => {
        // eslint-disable-next-line default-case
        switch (clientState) {
        case 0:
          this.connectionState = GuacStates.IDLE;
          break;
        case 1:
          // connecting ignored for some reason?
          break;
        case 2:
          this.connectionState = GuacStates.WAITING;
          break;
        case 3:
          this.connectionState = GuacStates.CONNECTED;
          this.display.scale(1);
          window.addEventListener('resize', this.resize);
          this.$refs.viewport.addEventListener('mouseenter', this.resize);

          GuacClipboard.setRemoteClipboard(this.client);

          // eslint-disable-next-line no-fallthrough
        case 4:
        case 5:
          // disconnected, disconnecting
          break;
        }
      };

      this.client.onerror = (error) => {
        this.client.disconnect();
        // eslint-disable-next-line no-console
        this.code = error.code;
        this.connectionState = GuacStates.CLIENT_ERROR;
      };

      this.client.onsync = () => {
      };

      // Test for argument mutability whenever an argument value is received
      this.client.onargv = (stream, mimetype, name) => {
        if (mimetype !== 'text/plain') { return; }

        const reader = new Guacamole.StringReader(stream);

        // Assemble received data into a single string
        let value = '';
        reader.ontext = (text) => {
          value += text;
        };

        // Test mutability once stream is finished, storing
        // the current value for the argument only if it is mutable
        reader.onend = () => {
          // eslint-disable-next-line no-shadow
          const stream = this.client.createArgumentValueStream('text/plain', name);
          stream.onack = (status) => {
            if (status.isError()) {
              // ignore reject
              return;
            }
            this.arguments[name] = value;
          };
        };
      };

      this.client.onclipboard = GuacClipboard.onClipboard;
      this.display = this.client.getDisplay();
      const displayElm = this.$refs.display;
      displayElm.appendChild(this.display.getElement());
      displayElm.addEventListener('contextmenu', (e) => {
        e.stopPropagation();
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      });
      this.client.connect(`token=${this.authToken}&`
             + 'GUAC_DATA_SOURCE=mysql&'
             + `GUAC_ID=${ this.identifier }&`
             + 'GUAC_TYPE=c&'
             + `GUAC_WIDTH=${ window.innerWidth }&`
             + `GUAC_HEIGHT=${ window.innerHeight }&`
             + 'GUAC_DPI=96&'
             + 'GUAC_TIMEZONE=Europe/Belgrade&'
             + 'GUAC_AUDIO=audio%2FL8&'
             + 'GUAC_AUDIO=audio%2FL16&'
             + 'GUAC_IMAGE=image%2Fjpeg&'
             + 'GUAC_IMAGE=image%2Fpng&'
             + 'GUAC_IMAGE=image%2Fwebp');

      this.mouse = new Guacamole.Mouse(displayElm);
      // Hide software cursor when mouse leaves display
      this.mouse.onmouseout = () => {
        if (!this.display) return;
        this.display.showCursor(false);
      };

      // allows focusing on the display div so that keyboard doesn't always go to session
      displayElm.onclick = () => {
        displayElm.focus();
      };
      displayElm.onfocus = () => {
        displayElm.className = 'focus';
      };
      displayElm.onblur = () => {
        displayElm.className = '';
      };

      this.keyboard = new Guacamole.Keyboard(displayElm);
      this.installKeyboard();
      // eslint-disable-next-line no-multi-assign
      this.mouse.onmousedown = this.mouse
        // eslint-disable-next-line no-multi-assign
        .onmouseup = this.mouse.onmousemove = this.handleMouseState;
      setTimeout(() => {
        this.resize();
        displayElm.focus();
      }, 1000); // $nextTick wasn't enough
    },
    installKeyboard() {
      this.keyboard.onkeydown = (keysym) => {
        this.client.sendKeyEvent(1, keysym);
      };
      this.keyboard.onkeyup = (keysym) => {
        this.client.sendKeyEvent(0, keysym);
      };
    },
    uninstallKeyboard() {
      // eslint-disable-next-line no-multi-assign
      this.keyboard.onkeydown = this.keyboard.onkeyup = () => {};
    },

    disconnectAndNotifyCloseTab() {
      if (this.client) {
        this.client.disconnect();
      }
      this.$store.dispatch(
        'remote-connection/guacamoleConnectionTab',
        { authToken: this.authToken, identifier: this.identifier, active: false },
      );
      window.close();
    },
  },
  mounted() {
    const route = window.location.pathname.split('/');
    this.authToken = route[route.length - 2];
    this.identifier = route[route.length - 1];
    if (!this.connected) {
      this.connected = true;
      this.connect();
    }
    window.addEventListener('beforeunload', this.disconnectAndNotifyCloseTab);

    this.$store.dispatch('remote-connection/guacamoleConnectionTab', { authToken: this.authToken, identifier: this.identifier, active: true });
  },
};
</script>

<style scoped>
  .viewport {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
</style>
