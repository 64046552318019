<template>
  <nerve-dialog
    v-model="status"
    scrollable
    persistent
    max-width="30vw"
    @keydown.esc="cancel()"
    @keydown.enter="confirm()"
  >
    <v-card v-if="code && code !== ''" class="guagamole-dialog" >
      <v-card-title>
        {{ $t('remoteConnection.dialog.errorTitle') }}
      </v-card-title>
      <v-card-text>
        <span>{{ $t(`remoteConnection.dialog.code:${code}`) }}</span>
      </v-card-text>

      <v-card-actions>
        <nerve-button
          id="iiotConnectingButtonCloseDialog"
          :text="$t('remoteConnection.dialog.close')"
          type-of-btn="cancel"
          size="normal"
          @click-event="cancel()"
        />
        <nerve-button
          id="iiotConnectingButtonReconnectDialog"
          :text="$t('remoteConnection.dialog.reconnect')"
          type-of-btn="action"
          size="normal"
          @click-event="confirm()"
        />
      </v-card-actions>
    </v-card>
    <v-card v-if="(status === 'IDLE' || status === 'DISCONNECTED'
        || status === 'CLIENT_ERROR' || status === 'TUNNEL_ERROR') && code === ''">
      <v-card-title>
        {{ $t('remoteConnection.dialog.disconnected') }}
      </v-card-title>
      <v-card-text>
        <span>{{ $t(`remoteConnection.dialog.disconnectedMessage`) }}</span>
      </v-card-text>

      <v-card-actions>
        <nerve-button
          id="iiotConnectingButtonCloseDialog"
          :text="$t('remoteConnection.dialog.close')"
          type-of-btn="cancel"
          size="normal"
          @click-event="cancel()"
        />
        <nerve-button
          id="iiotConnectingButtonReconnectDialog"
          :text="$t('remoteConnection.dialog.reconnect')"
          type-of-btn="action"
          size="normal"
          @click-event="$emit('reconnect')"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>
<script>

import { NerveButton, NerveDialog } from 'nerve-ui-components';
import GuacStates from '@/components/remote-connection/lib/GuacStates';

export default {
  components: { NerveButton, NerveDialog },
  data() {
    return {
      status: null,
      code: '',
    };
  },
  computed: {
    canReconnect() {
      return ['DISCONNECTED', 'CLIENT_ERROR'].includes(this.status);
    },
  },
  methods: {
    show(state, code) {
      if (state === GuacStates.CONNECTED) {
        this.status = null;
      } else {
        this.status = state;
      }
      this.code = code;
    },
    cancel() {
      window.close();
    },
    confirm() {
      this.$emit('reconnect');
    },
  },
};
</script>
<style scoped>
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 5px;
    padding: 1rem;
    background: #b4b4b4;
  }
  .rct {
    text-decoration: underline;
    cursor: pointer;
  }
  .guagamole-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
